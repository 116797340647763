import React from 'react';
import Core from "./pages/Core";
import "./styles/index.scss";


const App: React.FC = () => {
  return (
    <Core />
  );
};

export default App;
