import Typewriter from "typewriter-effect";

const Landing = () => {

    return (
        <>
            <div className="animation01">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="rotating-square">
               &nbsp;
            </div>
           

            <div className="intro">
                <Typewriter
                    options={{
                        autoStart: true,
                        loop: false,
                        cursor: "",
                        delay: 24,
                    }}
                    onInit={(typewriter) => {
                        typewriter
                            .pauseFor(300)
                            .typeString("FEAR<b>.</b>")
                            .pauseFor(300)
                            .typeString("UNCERTAINTY<b>.</b>")
                            .pauseFor(300)
                            .typeString("DOUBT<b>.</b>")
                            .pauseFor(300)
                            .deleteAll(1)
                            .typeString("<span>UNCOVER HIDDEN INSIGHTS<b>.</b></span>")
                            .pauseFor(600)
                            .deleteAll(1)
                            .typeString("<span>STAY AHEAD OF THE HERD<b>.</b></span>")
                            .pauseFor(1200)
                            .deleteAll(1)
                            .typeString("<span className='intro-logo'><b>FUD ATLAS</b><i className='fa-sharp fa-solid fa-arrow-right-long'></i></span>")
                            .start();
                    }}

                />


            </div>
        </>
    );
};

export default Landing;