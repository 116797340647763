import React from 'react';
import Landing from '../../components/Landing';
const Core: React.FC = () => {

  return (
    <>
      <Landing />
    </>
  );
};

export default Core;
